import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../components/SubLayout"
import { navItemsSr } from "../constants/navigation"

import React from "react"
import phoneNumber from "../constants/phoneNumber"
import { Flex, H1, Height, MaxWidth, P, Space, Svg } from "../styled/contact"

class Kontakt extends React.Component {
  render() {
    return (
      <Layout
        data={this.props.data}
        location={this.props.location}
        navItems={navItemsSr}
        content={() => (
          <>
            <Helmet
              title="Kontakt - Miia"
              meta={[
                { name: "description", content: "Sampleee" },
                { name: "keywords", content: "sampleee, something" },
              ]}
            />
            <Height>
              <div>
                <MaxWidth>
                  <H1>Kontaktirajte nas</H1>
                  <P>MIIA d.o.o.</P>
                  <Space />
                  <Flex>
                    <a>
                      <Svg
                        marginRight={14}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_409_4558)">
                          <path
                            d="M8 15.3332C8 15.3332 14 11.3332 14 6.6665C14 5.07521 13.3679 3.54908 12.2426 2.42386C11.1174 1.29864 9.5913 0.666504 8 0.666504C6.4087 0.666504 4.88258 1.29864 3.75736 2.42386C2.63214 3.54908 2 5.07521 2 6.6665C2 11.3332 8 15.3332 8 15.3332Z"
                            stroke="#7A7A7A"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 8.6665C9.10457 8.6665 10 7.77107 10 6.6665C10 5.56193 9.10457 4.6665 8 4.6665C6.89543 4.6665 6 5.56193 6 6.6665C6 7.77107 6.89543 8.6665 8 8.6665Z"
                            stroke="#7A7A7A"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_409_4558">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </Svg>
                    </a>

                    <P>Baštovanska 49/1, Kraljevo, Srbija</P>
                  </Flex>
                  <Space />
                  <Flex>
                    <Svg
                      marginRight={14}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_409_4563)">
                        <path
                          d="M14.6669 13.2802V11.2802C14.6751 10.9533 14.5627 10.6348 14.3513 10.3852C14.1399 10.1357 13.8441 9.97258 13.5203 9.9269C12.8802 9.84248 12.2518 9.68593 11.6469 9.46023C11.4083 9.37053 11.149 9.35112 10.8997 9.40429C10.6504 9.45746 10.4215 9.58099 10.2403 9.76023L9.39361 10.6069C7.72458 9.65786 6.34265 8.27592 5.39361 6.60689L6.24028 5.76023C6.41952 5.57897 6.54305 5.35012 6.59622 5.10081C6.64939 4.8515 6.62998 4.59218 6.54028 4.35356C6.31458 3.74871 6.15803 3.12027 6.07361 2.48023C6.02833 2.15986 5.8681 1.86689 5.62279 1.65592C5.37748 1.44495 5.06382 1.33038 4.74028 1.33356H2.74028C2.55517 1.33374 2.37213 1.37245 2.20281 1.44724C2.03348 1.52203 1.88159 1.63126 1.75679 1.76797C1.63199 1.90469 1.53703 2.06589 1.47795 2.24131C1.41886 2.41673 1.39695 2.60254 1.41361 2.78689C1.63244 4.84756 2.3336 6.8277 3.46028 8.56689C4.48384 10.1777 5.8495 11.5433 7.46028 12.5669C9.19161 13.6897 11.1622 14.3907 13.2136 14.6136C13.3985 14.6303 13.5849 14.6082 13.7608 14.5487C13.9367 14.4892 14.0982 14.3937 14.235 14.2681C14.3718 14.1426 14.4809 13.9899 14.5553 13.8198C14.6297 13.6497 14.6677 13.4659 14.6669 13.2802Z"
                          stroke="#7A7A7A"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_409_4563">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </Svg>
                    <P>{phoneNumber}</P>
                  </Flex>
                  <Space />
                  <Flex>
                    <Svg
                      marginRight={14}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.333 2.6665H2.66634C1.93301 2.6665 1.33301 3.2665 1.33301 3.99984V11.9998C1.33301 12.7332 1.93301 13.3332 2.66634 13.3332H13.333C14.0663 13.3332 14.6663 12.7332 14.6663 11.9998V3.99984C14.6663 3.2665 14.0663 2.6665 13.333 2.6665Z"
                        stroke="#7A7A7A"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.6663 4L7.99967 8.66667L1.33301 4"
                        stroke="#7A7A7A"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </Svg>
                    <P>miiakraljevo@gmail.com</P>
                  </Flex>
                  <Space />
                </MaxWidth>
                <div>
                  <P>Pratite nas</P>
                  <Space />
                  <Flex>
                    <a
                      href="https://www.facebook.com/profile.php?id=100086934069955"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Svg
                        marginRight={25}
                        style={{ cursor: "pointer" }}
                        width="10"
                        height="16"
                        viewBox="0 0 10 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.99984 1.3335H8.99984V4.00016H6.99984C6.82303 4.00016 6.65346 4.0704 6.52843 4.19542C6.40341 4.32045 6.33317 4.49002 6.33317 4.66683V6.66683H8.99984L8.33317 9.3335H6.33317V14.6668H3.6665V9.3335H1.6665V6.66683H3.6665V4.66683C3.6665 3.78277 4.01769 2.93493 4.64281 2.30981C5.26794 1.68469 6.11578 1.3335 6.99984 1.3335Z"
                          stroke="#7A7A7A"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </Svg>
                    </a>

                    <a
                      href="https://www.instagram.com/petmadraci/?igshid=YmMyMTA2M2Y%3D"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Svg
                        marginRight={0}
                        style={{ cursor: "pointer" }}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_409_4575)">
                          <path
                            d="M14.6668 4.66683C14.6668 2.82588 13.1744 1.3335 11.3335 1.3335H8.00016H4.66683C2.82588 1.3335 1.3335 2.82588 1.3335 4.66683V11.3335C1.3335 13.1744 2.82588 14.6668 4.66683 14.6668H11.3335C13.1744 14.6668 14.6668 13.1744 14.6668 11.3335V4.66683Z"
                            stroke="#7A7A7A"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.396 9.19954C10.6543 8.70168 10.7491 8.13503 10.6668 7.5802C10.5829 7.01425 10.3192 6.4903 9.91459 6.08574C9.51003 5.68118 8.98608 5.41746 8.42013 5.33353C7.8653 5.25126 7.29865 5.34603 6.80079 5.60436C6.30292 5.86269 5.89919 6.27144 5.64701 6.77245C5.39484 7.27346 5.30707 7.84124 5.39618 8.39501C5.48528 8.94878 5.74674 9.46036 6.14336 9.85698C6.53997 10.2536 7.05155 10.515 7.60532 10.6042C8.1591 10.6933 8.72687 10.6055 9.22788 10.3533C9.72889 10.1011 10.1376 9.69741 10.396 9.19954Z"
                            stroke="#7A7A7A"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.6665 4.3335H11.6732"
                            stroke="#7A7A7A"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_409_4575">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </Svg>
                    </a>
                  </Flex>
                </div>
              </div>
            </Height>
          </>
        )}
      ></Layout>
    )
  }
}

export default Kontakt

export const kontaktQuery = graphql`
  query kontaktQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    mainlogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      }
    }
  }
`
